import './Main.css';
import Hero from '../Images/001.webp';
import RSVP from './RSVP.js';
import Footer from './Footer.js';

function Main() {
	return (
		<div className='main'>
			<div className='Hero'>
				<img
					className='banner'
					src={Hero}
					alt="Let's RSVP this thing"
				/>
			</div>

			<RSVP />
			<Footer />
		</div>
	);
}

export default Main;
