import './App.css';
import Navbar from './Components/Navbar';
import Main from './Components/Main';

function App() {
	return (
		<>
			<Navbar />
			<Main />
		</>
	);
}

export default App;
