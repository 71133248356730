import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './RSVP.css';

const Form = () => {
	const [selectedLanguage, setSelectedLanguage] = useState('');
	const [willAttend, setWillAttend] = useState(true); // Set "Yes" as the default
	const [formData, setFormData] = useState({
		FirstLastName: '',
		NumOfGuests: '',
		GuestNames: '',
		Info: '',
	});
	const [submitted, setSubmitted] = useState(false); // New state variable

	const handleLanguageChange = (e) => {
		setSelectedLanguage(e.target.value);
		setFormData({
			FirstLastName: '',
			NumOfGuests: '',
			GuestNames: '',
			Info: '',
		});
	};

	const handleAttendanceChange = (e) => {
		setWillAttend(e.target.value === 'Yes');
		setFormData({
			FirstLastName: '',
			NumOfGuests: '',
			GuestNames: '',
			Info: '',
		});
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormData((prevData) => ({
			...prevData,
			[name]: value,
		}));
	};

	const form = useRef();

	const sendEmail = (e) => {
		e.preventDefault();

		emailjs
			.sendForm('service_4z4agxk', 'template_zcs3kxk', form.current, {
				publicKey: 'id5sOR7f3NLG6WbjE',
			})
			.then(
				() => {
					console.log('SUCCESS!');
					setSubmitted(true); // Update state after successful submission
					// Scroll to the bottom of the page
					window.scrollTo({
						top: document.documentElement.scrollHeight,
						behavior: 'smooth',
					});
					setTimeout(() => {
						window.location.href = 'https://kelsiandedwin.com'; // Redirect after 8 seconds
					}, 8000);
				},
				(error) => {
					console.log('FAILED...', error.text);
				}
			);
	};

	if (submitted) {
		return (
			<div>
				<br />
				<br />
				<h1>Thank you for your RSVP!</h1>
				<h4>You're now being redirected to our homepage.</h4>
				<br />
				<h1>❤️</h1>
			</div>
		);
	}

	return (
		<form
			ref={form}
			onSubmit={sendEmail}
		>
			{/* Language Selection */}
			<fieldset>
				<legend className='languageSelect'>
					Select a language - Selecionar un idioma
				</legend>
				<label>
					<input
						type='checkbox'
						name='Language'
						value='english'
						checked={selectedLanguage === 'english'}
						onChange={handleLanguageChange}
					/>
					English
				</label>
				<label>
					<input
						type='checkbox'
						name='Language'
						value='spanish'
						checked={selectedLanguage === 'spanish'}
						onChange={handleLanguageChange}
					/>
					Español
				</label>
			</fieldset>
			<br />

			{/* Will Attend??? */}
			{selectedLanguage && (
				<fieldset>
					<legend>
						{selectedLanguage === 'english'
							? "Will you be attending Kelsi & Edwin's wedding on May 4th 2024?"
							: '¿Asistirás a la boda de Kelsi y Edwin el 4 de Mayo 2024?'}
					</legend>
					<label>
						<input
							type='checkbox'
							name='WillAttend'
							value='Yes'
							checked={willAttend}
							onChange={handleAttendanceChange}
						/>
						{selectedLanguage === 'english' ? 'Yes' : 'Sí'}
					</label>
					<label>
						<input
							type='checkbox'
							name='WillAttend'
							value='No'
							checked={!willAttend}
							onChange={handleAttendanceChange}
						/>
						No
					</label>
				</fieldset>
			)}
			<br />

			{/* Is Attending Questions*/}
			{selectedLanguage && willAttend && (
				<fieldset>
					<label>
						{selectedLanguage === 'english'
							? 'Your First & Last Name'
							: 'Su Nombre & Apellido'}
						<br />
						<input
							required
							className='FirstLastName'
							type='text'
							name='FirstLastName'
							value={formData.FirstLastName}
							onChange={handleInputChange}
						/>
					</label>
					<br />
					<br />
					<label>
						{selectedLanguage === 'english'
							? 'How many guests, including yourself, will be joining us?'
							: '¿Cuántos invitados, incluyéndote a ti, nos acompañarán?'}
						<br />
						<select
							id='guests'
							name='NumOfGuests'
							value={formData.NumOfGuests}
							onChange={handleInputChange}
						>
							<option value='1'>1</option>
							<option value='2'>2</option>
							<option value='3'>3</option>
						</select>
					</label>
					<br />
					<br />
					<label>
						{selectedLanguage === 'english'
							? 'Please list the names of the people who will be coming with you, other than yourself.'
							: 'Por favor, lista los nombres de las personas que vendrán contigo, aparte de ti mismo.'}
						<br />
						<textarea
							className='GuestNames'
							name='GuestNames'
							placeholder={
								selectedLanguage === 'english'
									? 'Please separate names by using a comma.'
									: 'Por favor, separa los nombres usando una coma."'
							}
							value={formData.GuestNames}
							onChange={handleInputChange}
						/>
					</label>
					<br />
					<br />
					<label>
						{selectedLanguage === 'english'
							? 'Any other specific information you would like to provide?'
							: '¿Algún otro dato específico que te gustaría proporcionar?'}
						<br />
						<textarea
							placeholder={
								selectedLanguage === 'english'
									? 'Please list any food restrictions and/or accessibility concerns we should know about so that we may best accomodate you!'
									: 'Por favor, enumera cualquier restricción alimentaria y/o preocupación de accesibilidad que debamos conocer para poder acomodarte de la mejor manera posible.'
							}
							name='Info'
							value={formData.Info}
							onChange={handleInputChange}
						/>
					</label>
				</fieldset>
			)}

			{/* Is NOT Attending */}
			{!willAttend && selectedLanguage && (
				<fieldset>
					<label>
						{selectedLanguage === 'english'
							? 'Your First & Last Name:'
							: 'Su Nombre & Apellido:'}
						<br />
						<input
							required
							className='FirstLastName'
							type='text'
							name='FirstLastName'
							value={formData.FirstLastName}
							onChange={handleInputChange}
						/>
					</label>
					<br />
					<br />
					<br />
					<h3>
						{selectedLanguage === 'english'
							? 'Thank you for letting us know! Hopefully we can celabrate another time!'
							: '¡Gracias por avisarnos! ¡Esperamos poder celebrar en otra ocasión!'}
					</h3>
				</fieldset>
			)}
			<br />

			{/* Submit Button */}
			{selectedLanguage && (
				<button
					type='submit'
					value='Send'
					class='btn btn-primary'
				>
					<h5 className='submit'>Submit</h5>
				</button>
			)}
		</form>
	);
};

export default Form;
